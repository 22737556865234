<template>
  <v-container fluid>
    <v-layout row wrap>
      <v-flex xs12 class="text-xs-center" mt-5>
        <h1>Secret User page</h1>
      </v-flex>
      <v-flex xs12 class="text-xs-center" mt-3>
        <p>O_O
        </p>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {}
</script>
